import { classnames } from '@tailwindcss-classnames';

const bgGrey700 = classnames('bg-nzxt-dark-grey-700');
export const input = (isInverted?: boolean): string =>
  classnames(
    'h-4',
    'w-4',
    'text-nzxt-volt-400',
    'focus:text-nzxt-volt-400',
    'border-gray-600',
    'rounded',
    'focus:outline-none',
    'focus:ring-nzxt-volt-400',
    'focus:border-nzxt-volt-400',
    'disabled:bg-nzxt-light-grey-100',
    'dark:disabled:bg-nzxt-dark-grey-500',
    'dark:border-nzxt-dark-grey-400',
    {
      [bgGrey700]: isInverted,
    }
  );

export const checkBoxLabel = classnames('flex', 'items-center', 'text-sm');

const textWhite = classnames('text-white');
export const checkBoxText = (isInverted?: boolean): string =>
  classnames('ml-2', {
    [textWhite]: isInverted,
  });

export const errorText = classnames('text-red-500', 'mt-2', 'ml-6', 'text-xs');
