import { FC, memo } from 'react';
import cx from 'clsx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';
import listStyles from '../TextBlock/listStyles.module.css';
import * as styles from './styles';

type Props = {
  subtext?: string;
  subtextMarkdownContent?: MDXRemoteSerializeResult;
  subtextBackground?: string;
  isInverted?: boolean;
  noSpace?: boolean;
};

const SubtextBlock: FC<Props> = ({
  subtext,
  subtextMarkdownContent,
  subtextBackground,
  isInverted,
  noSpace = false,
}) => {
  const hasContent = Boolean(subtextMarkdownContent || subtext);

  return (
    <div
      className={styles.contentWrapper(noSpace)}
      style={{ backgroundColor: subtextBackground }}
    >
      {hasContent && (
        <div
          className={cx(
            styles.textWrapper(isInverted),
            listStyles.linkUnderline
          )}
        >
          {subtextMarkdownContent ? (
            <HydrateMdx mdx={subtextMarkdownContent} />
          ) : (
            subtext
          )}
        </div>
      )}
    </div>
  );
};

export default memo(SubtextBlock);
