import { memo, FC, useState, useEffect, useMemo, useRef } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import cn from 'clsx';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import type { DatoProductImage } from '@framework/api/types';

import { useMedia } from '@utils/hooks';

import Testimonial from './Testimonial';
import cssStyles from './Testimonial.module.css';
import 'keen-slider/keen-slider.min.css';

import * as styles from './styles';

type TestimonialData = {
  author?: string;
  quote: string;
  logo?: DatoProductImage;
  authorImage?: DatoProductImage;
};
type Props = {
  testimonialsData: TestimonialData[];
};

// to do: clean up CSS styles once direction is confirmed

const getSliderPerView = (isSm, isMed): number => {
  switch (true) {
    case isSm:
      return 1;
    case isMed:
      return 1.55;
    case !isSm && !isMed:
      return 2.55;

    default:
      return 1;
  }
};

const TestimonialsCarousel: FC<Props> = ({ testimonialsData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const timer = useRef(null);

  const isSm = useMedia('sm');
  const isMd = useMedia('md');

  const size = useMemo(() => getSliderPerView(isSm, isMd), [isSm, isMd]);

  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: { perView: size, origin: 'center', spacing: 15 },
    mode: 'free-snap',
    loop: true,
    slideChanged(slide) {
      setCurrentSlide(slide.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line
    timer.current = setInterval(() => {
      if (isSm && instanceRef?.current) {
        instanceRef?.current.next();
      }
    }, 3000);

    return () => {
      clearInterval(timer.current);
    };
  }, [instanceRef, isSm]);

  return (
    <div
      className={cn('keen-slider', cssStyles.testimonialFade, styles.wrapper)}
      ref={ref}
    >
      {testimonialsData.map((testi, i) => (
        <Testimonial
          // eslint-disable-next-line react/no-array-index-key
          key={`${testi.quote}-${i}`}
          author={testi.author}
          authorImage={testi.authorImage}
          quote={testi.quote}
          logo={testi.logo}
          index={i}
          isActive={i === currentSlide}
        />
      ))}
      {!isSm && loaded && instanceRef.current && (
        <>
          <button
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any): void =>
              e.stopPropagation() || instanceRef.current?.prev()
            }
            type="button"
            style={{ top: '40%', left: '0px', zIndex: 2 }}
            className={styles.sliderButton}
          >
            <IconChevronLeft className={styles.arrow} />
          </button>
          <button
            type="button"
            style={{ top: '40%', right: '0px', zIndex: 2 }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any): void =>
              e.stopPropagation() || instanceRef.current?.next()
            }
            className={styles.sliderButton}
          >
            <IconChevronRight className={styles.arrow} />
          </button>
        </>
      )}
    </div>
  );
};

export default memo(TestimonialsCarousel);
