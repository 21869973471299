import { memo, FC } from 'react';
import type { DatoProductImage } from '@framework/api/types';
import Testimonial from './Testimonial';

import * as styles from './styles';

type TestimonialData = {
  author?: string;
  quote: string;
  logo?: DatoProductImage;
  authorImage?: DatoProductImage;
};
type Props = {
  testimonialsData: TestimonialData[];
  isLarge?: boolean;
};

const TestimonialsCarousel: FC<Props> = ({
  testimonialsData,
  isLarge = true,
}) => (
  <div className={styles.testimonialWrapper}>
    {testimonialsData.map((testi, i) => (
      <Testimonial
        // eslint-disable-next-line react/no-array-index-key
        key={`${testi.quote}-${i}`}
        author={testi.author}
        authorImage={testi.authorImage}
        quote={testi.quote}
        logo={testi.logo}
        noCarousel={isLarge}
      />
    ))}
  </div>
);

export default memo(TestimonialsCarousel);
