import { memo, FC } from 'react';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import type { DatoProductImage } from '@framework/api/types';

import { useMedia } from '@utils/hooks';

import TestimonialCarousel from './TestimonialCarousel';
import TestimonialLarge from './TestimonialLarge';

type TestimonialData = {
  author?: string;
  quote: string;
  logo?: DatoProductImage;
  authorImage?: DatoProductImage;
};
type Props = {
  testimonialsData: TestimonialData[];
  noWrapperSpacing?: boolean;
};

const Testimonials: FC<Props> = ({
  testimonialsData,
  noWrapperSpacing = false,
}) => {
  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const slideMinHeight = isSm ? '350px' : '250px';
  return (
    <SectionWrapper
      style={{
        minHeight: slideMinHeight,
        maxHeight: '350px',
        overflow: 'hidden',
      }}
      noSpacing={noWrapperSpacing}
      data-test-id="testimonial-block"
    >
      <ContentWrapper>
        {isSm || isMd ? (
          <TestimonialCarousel testimonialsData={testimonialsData} />
        ) : (
          <TestimonialLarge testimonialsData={testimonialsData} isLarge />
        )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default memo(Testimonials);
