import { memo, FC, useEffect, useState } from 'react';
import type { DatoProduct } from '@framework/api/types';

import SwimLaneWrapperProduct from './SwimLaneWrapperProduct';

type Props = {
  products: DatoProduct[];
  heading?: string;
  headingStyles?: string;
  currencyCode?: string;
  isExtendedCard?: boolean;
  asRelatedItems?: boolean;
  eventType?: string;
};

const SwimLaneWrapper: FC<Props> = ({
  products,
  heading,
  currencyCode,
  headingStyles,
  isExtendedCard,
  asRelatedItems = false,
  eventType,
}) => {
  const [relatedItemsKey, setrelatedItemsKey] = useState(0);
  useEffect(() => {
    setrelatedItemsKey(relatedItemsKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <SwimLaneWrapperProduct
      key={relatedItemsKey}
      products={products}
      heading={heading}
      headingStyles={headingStyles}
      currencyCode={currencyCode}
      asRelatedItems={asRelatedItems}
      isExtendedCard={isExtendedCard}
      eventType={eventType}
    />
  );
};

export default memo(SwimLaneWrapper);
