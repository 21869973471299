import { memo, FC } from 'react';
import type { DatoProductImage } from '@framework/api/types';
import Image from '@components/Image';

import 'keen-slider/keen-slider.min.css';
import * as styles from './styles';

type Props = {
  author?: string;
  quote: string;
  logo?: DatoProductImage;
  authorImage?: DatoProductImage;
  index?: number;
  isActive?: boolean;
  noCarousel?: boolean;
};

const Testimonial: FC<Props> = ({
  author,
  quote,
  logo,
  authorImage,
  index,
  isActive,
}) => (
  <div
    style={{
      overflow: 'hidden',
      minHeight: '250px',
    }}
    className={`keen-slider__slide number-slide${index}`}
    data-test-id="testimonial-item"
  >
    <div className={styles.innerWrapper}>
      <div className={styles.imageWrapper}>
        {logo && (
          <Image
            data={logo.responsiveImage}
            width={logo.responsiveImage.width}
            height={logo.responsiveImage.height}
          />
        )}
      </div>
      <blockquote className={styles.blockquote}>
        <div className={styles.innerBlockquote(isActive)}>
          <p>&ldquo;{quote}&rdquo;</p>
        </div>
        <div className={styles.testimonialFooterWrapper}>
          <div className={styles.innerTestimonialFooter}>
            <div className={styles.authorImageWrapper}>
              {author && authorImage && (
                <Image
                  data={authorImage.responsiveImage}
                  width={authorImage.responsiveImage.width}
                  height={authorImage.responsiveImage.height}
                  className={styles.authorImage(isActive)}
                />
              )}
            </div>
            {author && (
              <div className={styles.authorWrapper}>
                <div className={styles.author(isActive)}>{author}</div>
              </div>
            )}
          </div>
        </div>
      </blockquote>
    </div>
  </div>
);

export default memo(Testimonial);
