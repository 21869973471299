/* eslint-disable no-underscore-dangle */
import { FC, memo, useMemo } from 'react';
import cx from 'clsx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import type {
  DatoProductImage,
  ButtonGroup,
  MarkdownIconBlocks,
} from '@framework/api/types';
import Image from '@components/Image';
import LinkGroup from '@components/LinkGroup';
import HydrateMdx from '@components/HydrateMdx';
import IconBlock from '../IconBlock';
import listStyles from './listStyles.module.css';
import * as styles from './styles';

interface Props {
  darkTheme?: boolean;
  textThemeColor?: string;
  textPosition?: string;
  eyebrowLogo?: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  markdownContent?: MDXRemoteSerializeResult;
  copy?: string;
  buttons?: ButtonGroup[];
  markdownIconBlocks?: MarkdownIconBlocks[];
}

const TextBlock: FC<Props> = ({
  darkTheme,
  textThemeColor,
  textPosition,
  eyebrowLogo,
  eyebrow,
  heading,
  markdownContent,
  copy,
  buttons,
  markdownIconBlocks,
}) => {
  const hasContent = Boolean(markdownContent || copy);
  const isCenter = textPosition === 'bottom' || textPosition === 'top';
  const sanitizedThemeColor = textThemeColor.toLowerCase() || 'default';

  const composedClassNameCopy = useMemo(
    () => cx(styles.copyWrapper, listStyles.list, styles.textColor(darkTheme)),
    [darkTheme]
  );

  const copyStyles = darkTheme ? styles.copyColorDark : styles.copyColorLight;

  const composedClassNameEyebrow = useMemo(
    () => cx(copyStyles[sanitizedThemeColor]),
    [copyStyles, sanitizedThemeColor]
  );

  return heading || eyebrow || copy ? (
    <div
      className={cx(
        styles.textWrapper(isCenter),
        styles.textPlacementSpacing[textPosition]
      )}
    >
      {eyebrowLogo && (
        <Image
          data={eyebrowLogo?.responsiveImage}
          width={eyebrowLogo?.responsiveImage?.width}
          height={eyebrowLogo?.responsiveImage?.height}
          style={{ maxWidth: '200px' }}
          className={styles.eyebrowLogo(isCenter)}
        />
      )}
      {eyebrow && <p className={composedClassNameEyebrow}>{eyebrow}</p>}
      {heading && (
        <h2 className={cx(styles.heading, styles.textColor(darkTheme))}>
          {heading}
        </h2>
      )}
      {hasContent && (
        <div className={composedClassNameCopy}>
          {markdownContent ? <HydrateMdx mdx={markdownContent} /> : copy}
        </div>
      )}

      {Array.isArray(markdownIconBlocks) && markdownIconBlocks?.length > 0 && (
        <div className={styles.markdownIconBlocksWrapper}>
          {markdownIconBlocks?.map(item => (
            <IconBlock
              heading={item.heading}
              decoratedMarkdownContent={
                item?.markdownCopy && item.decoratedMarkdownContent
              }
              markdownContent={item.markdownCopy}
              iconName={item.iconName}
              key={item.id}
              darkTheme={darkTheme}
            />
          ))}
        </div>
      )}
      {Array.isArray(buttons) && buttons?.length > 0 && (
        <div className={styles.buttonContainer(isCenter)}>
          <LinkGroup
            links={buttons}
            alignment={isCenter ? 'center' : 'left'}
            isInverted={!darkTheme}
          />
        </div>
      )}
    </div>
  ) : null;
};

export default memo(TextBlock);
