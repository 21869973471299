import { FC, InputHTMLAttributes, ReactNode, memo, forwardRef } from 'react';
import cn from 'clsx';
import * as styles from './styles';

type Classes = {
  inputClass?: string;
  labelClass?: string;
  checkBoxTextClass?: string;
};

export interface ForwardedInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  classes?: Classes;
  labelFor: string;
  checkBoxLabel: string | ReactNode;
  requiredError?: boolean;
  requiredMessage?: string;
  isInverted?: boolean;
  srOnly?: boolean;
}

const CheckboxInput: FC<ForwardedInputProps> = forwardRef<
  HTMLInputElement,
  ForwardedInputProps
>(
  (
    {
      classes,
      labelFor,
      checkBoxLabel,
      requiredError,
      requiredMessage,
      isInverted,
      srOnly,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(styles.input(isInverted), {}, classes?.inputClass);
    const labelClassName = cn(styles.checkBoxLabel, {}, classes?.labelClass);
    const textClassname = cn(
      styles.checkBoxText(isInverted),
      { 'sr-only': srOnly },
      classes?.checkBoxTextClass
    );

    return (
      <>
        <label htmlFor={labelFor} className={labelClassName}>
          <input
            type="checkbox"
            className={rootClassName}
            id={labelFor}
            {...rest}
            ref={ref}
          />
          <span className={textClassname}>{checkBoxLabel}</span>
        </label>
        {requiredError && <p className={styles.errorText}>{requiredMessage}</p>}
      </>
    );
  }
);

export default memo(CheckboxInput);
