/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import HydrateMdx from '@components/HydrateMdx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import IconDynamic from '@components/IconDynamic';
import * as styles from './styles';

type Props = {
  heading?: string;
  iconName?: string;
  decoratedMarkdownContent?: MDXRemoteSerializeResult;
  markdownContent?: string;
  darkTheme?: boolean;
  smallIcon?: boolean;
  noContentWrapper?: boolean;
};

const IconBlock: FC<Props> = ({
  heading,
  iconName,
  decoratedMarkdownContent,
  markdownContent,
  smallIcon = false,
  darkTheme = false,
  noContentWrapper = false,
}) => {
  const hasContent = Boolean(decoratedMarkdownContent || markdownContent);
  return (
    <div className={styles.iconBlockwrapper(noContentWrapper)}>
      {iconName && (
        <div className={styles.iconWrapper}>
          <IconDynamic
            iconName={iconName}
            className={styles.icon(darkTheme, smallIcon)}
          />
        </div>
      )}
      <div
        className={styles.iconBlockContentWrapper(
          Boolean(decoratedMarkdownContent)
        )}
      >
        {heading && (
          <h4 className={styles.iconBlockHeading(darkTheme)}>{heading}</h4>
        )}
        {hasContent && (
          <div className={styles.iconBlockMDXWrapper(darkTheme)}>
            {decoratedMarkdownContent ? (
              <HydrateMdx mdx={decoratedMarkdownContent} />
            ) : (
              markdownContent
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(IconBlock);
