import { classnames } from '@tailwindcss-classnames';

export const title = classnames(
  'text-nzxt-light-grey-800',
  'dark:text-white',
  'h3',
  'pb-3',
  'md:pb-6',
  'mb-6',
  'md:mb-10',
  'border-b',
  'dark:border-nzxt-dark-grey-200'
);

export const headingWrapper = classnames(
  'w-11/12',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'lg:px-12'
);

export default title;
