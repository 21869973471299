import { classnames } from '@tailwindcss-classnames';

export const innerWrapper = classnames('relative');
export const imageWrapper = classnames(
  'mx-auto',
  'flex',
  'justify-center',
  'items-center'
);
export const blockquote = classnames('mt-10');

const activeQuote = classnames('opacity-100', 'text-lg');
const inactiveQuote = classnames('text-sm', 'opacity-50', 'lg:opacity-100');

export const innerBlockquote = (isActive: boolean): string =>
  classnames(
    'max-w-md',
    'mx-auto',
    'text-center',
    'leading-7',
    'md:leading-6',
    'font-medium',
    'px-4',
    'text-nzxt-light-grey-800',
    'transition-opacity',
    'duration-200',
    'ease-in-out',
    { [activeQuote]: isActive, [inactiveQuote]: !isActive }
  );

export const testimonialFooterWrapper = classnames('mt-8');
export const innerTestimonialFooter = classnames(
  'md:flex',
  'md:items-center',
  'md:justify-center'
);
export const authorImageWrapper = classnames('md:flex-shrink-0');

const activeImage = classnames('opacity-100');
const inactiveImage = classnames('opacity-50', 'lg:opacity-100');

export const authorImage = (isActive: boolean): string =>
  classnames(
    imageWrapper,
    'h-10',
    'w-10',
    'rounded-full',
    'transition-opacity',
    'duration-200',
    'ease-in-out',
    { [activeImage]: isActive, [inactiveImage]: !isActive }
  );

const activeAuthor = classnames('text-sm', 'opacity-100');
const inactiveAuthor = classnames('text-xs', 'opacity-50', 'lg:opacity-100');
export const author = (isActive: boolean): string =>
  classnames(
    'font-medium',
    'text-gray-900',
    'transition-opacity',
    'duration-200',
    'ease-in-out',
    { [activeAuthor]: isActive, [inactiveAuthor]: !isActive }
  );

export const authorWrapper = classnames(
  'mt-3',
  'text-center',
  'md:mt-0',
  'md:ml-4',
  'md:flex',
  'md:items-center'
);

export const authorName = classnames(
  'text-base',
  'font-medium',
  'text-nzxt-light-grey-800'
);
export const arrow = classnames(
  'h-6',
  'w-6',
  'text-nzxt-light-grey-900',
  'stroke-current'
);
export const sliderButton = classnames(
  'focus:outline-none',
  'absolute',
  'bg-white',
  'rounded-full',
  'flex',
  'items-center',
  'justify-center',
  'p-2'
);

// testimonials large screen
export const testimonialWrapper = classnames('flex', 'space-x-4');
export const wrapper = classnames('overflow-hidden');
