import { classnames } from '@tailwindcss-classnames';

const textAlignmentDefault = classnames('text-left');

const buttonAlignmentDefault = classnames();
const center = classnames('md:mx-auto', 'text-center');

const darkText = classnames('text-nzxt-light-grey-800');
const lightText = classnames('text-white');

const darkTextCopy = classnames('text-nzxt-light-grey-600');
const lightTextCopy = classnames('text-nzxt-light-grey-200');

export const textColor = (isInverted: boolean): string =>
  classnames({
    [darkText]: isInverted,
    [lightText]: !isInverted,
  });

export const bodyCopy = (isInverted: boolean): string =>
  classnames({
    [darkTextCopy]: isInverted,
    [lightTextCopy]: !isInverted,
  });

export const copyColorLight = {
  default: lightText,
  intel: classnames('text-intel'),
  nvidia: classnames('text-nvidia'),
  amd: classnames('text-amd'),
  blue: classnames('text-nzxt-blue-300'),
  green: classnames('text-nzxt-green-400'),
  yellow: classnames('text-nzxt-yellow-150'),
  red: classnames('text-nzxt-red-300'),
};

export const copyColorDark = {
  default: darkText,
  green: classnames('text-nzxt-green-400'),
  blue: classnames('text-nzxt-blue-300'),
  red: classnames('text-nzxt-red-300'),
  intel: classnames('text-intel'),
  nvidia: classnames('text-nvidia'),
  amd: classnames('text-amd'),
  yellow: classnames('text-nzxt-yellow-150'),
};

const flexCenter = classnames('md:items-center', 'md:justify-center');

const spacingTop = classnames('mt-3', 'md:mt-6');
const spacingBottom = classnames('mb-3', 'md:mb-6');
const baseSpacing = classnames('mt-6');

const left = classnames(baseSpacing, 'lg:mt-0', textAlignmentDefault);
const bottom = classnames(baseSpacing, 'md:mt-8', 'md:text-center');
const top = classnames(baseSpacing, 'lg:mt-0', 'md:text-center');
const right = classnames(baseSpacing, 'lg:ml-12', textAlignmentDefault);

const centeredWidth = classnames('max-w-xl');
const defaultWidth = classnames('max-w-lg', 'lg:max-w-md');
const centeredSpacing = classnames('md:px-4');

export const heading = classnames('h3', spacingBottom);
export const buttonContainer = (isCenter: boolean): string =>
  classnames(
    spacingTop,
    'flex',
    'flex-col',
    'md:flex-row',
    'space-x-3',
    'space-y-2',
    'md:space-y-0',
    {
      [flexCenter]: isCenter,
      [buttonAlignmentDefault]: !isCenter,
    }
  );

export const textWrapper = (isCenter: boolean): string =>
  classnames({
    [centeredWidth]: isCenter,
    [defaultWidth]: !isCenter,
  });

export const copyWrapper = (isCenter: boolean): string =>
  classnames('prose', {
    [centeredSpacing]: isCenter,
  });

export const textPlacementSpacing = {
  left,
  bottom,
  top,
  right,
};

export const eyebrowLogo = (isCenter: boolean): string =>
  classnames({ [center]: isCenter, [textAlignmentDefault]: !isCenter }, 'mb-2');

export const markdownIconBlocksWrapper = classnames(
  'mt-4',
  'mb-4',
  'space-y-8'
);
