import { classnames } from '@tailwindcss-classnames';

export const centeredItems = classnames('justify-center', 'flex', 'relative');

export const subheading = classnames('pb-12', 'font-semibold');
const activeGlow = classnames('border-b-2', 'border-nzxt-volt-300');

export const imageWrapper = (isActive: boolean): string =>
  classnames(
    { [activeGlow]: isActive },
    'flex',
    'flex-col',
    'flex-shrink-0',
    'items-center',
    'pb-3',
    'focus:outline-none',
    'mx-auto'
  );

export const imageWrapperBase = (isActive: boolean): string =>
  classnames(
    { [activeGlow]: isActive },
    'flex',
    'flex-col',
    'flex-shrink-0',
    'items-center',
    'pb-3',
    'focus:outline-none',
    'mx-auto',
    'px-4'
  );

export const topHeading = classnames(
  'font-bold',
  'text-base',
  'md:text-lg',
  'text-nzxt-light-grey-800'
);
export const srOnly = classnames('sr-only');

export const item = classnames('flex');
export const cardSpacing = classnames('mx-auto', 'px-0', 'xl:px-6', 'h-full');
export const fewItemsSpacin = classnames('px-4');

const activeArrow = classnames('opacity-100');
const inactiveArrow = classnames('opacity-50');
export const arrow = (isLast: boolean): string =>
  classnames(
    { [activeArrow]: !isLast, [inactiveArrow]: isLast },
    'h-6',
    'w-6',
    'text-nzxt-light-grey-900',
    'stroke-current'
  );
export const sliderButton = classnames(
  'focus:outline-none',
  'absolute',
  'bg-white',
  'border',
  'rounded-full',
  'flex',
  'items-center',
  'justify-center',
  'p-2'
);
export const fewItemsGrid = classnames('grid', 'grid-cols-3');
export const headingWrapper = classnames(
  'w-11/12',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'lg:px-12'
);

export const relative = classnames('relative');
