import { FC, memo, Fragment, ReactNode } from 'react';
import cx from 'clsx';
import type { mediaObjectProps } from '@framework/api/types';
import Image from '@components/Image';
import SectionWrapper from '@components/SectionWrapper';
import TextBlock from './TextBlock';
import SubtextBlock from './SubtextBlock';
import * as styles from './styles';

type Props = {
  noSpace?: boolean;
  mediaObjects?: mediaObjectProps[];
  noWrapper?: boolean;
  children?: ReactNode;
};

const Section: FC<Props> = ({ children }) => (
  <SectionWrapper data-test-id="media-object-block">{children}</SectionWrapper>
);

const FauxWrapper: FC<Props> = ({ children }) => (
  <section data-test-id="media-object-block">{children}</section>
);

const MediaObject: FC<Props> = ({
  mediaObjects,
  noSpace = false,
  noWrapper = false,
}) => {
  const Wrapper = noSpace || noWrapper ? FauxWrapper : Section;

  const getBGposition = (pos: string): string => {
    if (pos === 'right') {
      return 'left bottom';
    }

    if (pos === 'left') {
      return 'right bottom';
    }

    return 'top left';
  };

  const getStyleForRounding = (
    isMoreThanOne: boolean,
    isFirst: boolean,
    isLast: boolean,
    hasSubtext: boolean,
    isNoSpace: boolean
  ): string => {
    if (isFirst && isMoreThanOne && isNoSpace && !hasSubtext) {
      return styles.firstRounding;
    }
    if (isLast && isMoreThanOne && isNoSpace && !hasSubtext) {
      return styles.lastRounding;
    }

    if (hasSubtext && isNoSpace && isFirst) {
      return styles.firstRounding;
    }

    if (hasSubtext && !isNoSpace) {
      return styles.firstRounding;
    }

    if (!hasSubtext && isNoSpace) {
      return null;
    }

    return styles.defaultRounding;
  };
  // TODO STILL BROKEN ON WITH  SUBTEXT
  return Array.isArray(mediaObjects) && mediaObjects?.length > 0 ? (
    <Wrapper>
      {mediaObjects?.map((object, index) => (
        <Fragment key={object?.id}>
          <div
            className={cx(
              styles.contentWrapper,
              styles.spacingWrapper(Boolean(mediaObjects?.length > 1), noSpace)
            )}
            data-test-id="media-object-item"
          >
            <div
              className={cx(
                getStyleForRounding(
                  Boolean(mediaObjects?.length > 1),
                  Boolean(index === 0),
                  Boolean(index === mediaObjects?.length - 1),
                  Boolean(object?.subtext || object?.subtextMarkdownContent),
                  noSpace
                ),
                styles.backgroundContainer
              )}
              style={{
                backgroundImage:
                  object?.backgroundImage &&
                  `url(${object?.backgroundImage?.responsiveImage.src})`,
                backgroundColor: object?.backgroundColor || null,
                backgroundSize: 'cover',
                backgroundPosition: getBGposition(
                  object?.textPosition?.toLowerCase() || 'top left'
                ),
              }}
            >
              <div
                className={
                  styles.textPlacement[
                    object?.textPosition?.toLowerCase() || 'left'
                  ]
                }
              >
                <TextBlock
                  darkTheme={object?.darkTheme}
                  textThemeColor={object?.textThemeColor}
                  textPosition={object?.textPosition?.toLowerCase() || 'left'}
                  eyebrowLogo={object?.eyebrowLogo}
                  eyebrow={object?.eyebrow}
                  heading={object?.heading}
                  markdownContent={object?.markdownContent}
                  copy={object?.copy}
                  buttons={object?.buttons}
                  markdownIconBlocks={object?.markdownIconBlocks}
                />
                {object?.image && (
                  <Image
                    data={object?.image?.responsiveImage}
                    width={object?.image?.responsiveImage?.width}
                    height={object?.image?.responsiveImage?.height}
                    className={styles.image}
                  />
                )}
              </div>

              {object?.partnerLogo && (
                <div className={styles.partnerLogoPos}>
                  <Image
                    data={object?.partnerLogo?.responsiveImage}
                    width={object?.partnerLogo?.responsiveImage?.width}
                    height={object?.partnerLogo?.responsiveImage?.height}
                    style={{ maxWidth: '100px', height: '100%' }}
                    className={styles.partnerLogo}
                  />
                </div>
              )}
            </div>
            {(object?.subtext || object?.subtextMarkdownContent) && (
              <SubtextBlock
                subtext={object?.subtext}
                subtextMarkdownContent={object?.subtextMarkdownContent}
                subtextBackground={object?.subtextBackground}
                isInverted={object?.darkTheme}
                noSpace={noSpace}
              />
            )}
          </div>
        </Fragment>
      ))}
    </Wrapper>
  ) : null;
};

export default memo(MediaObject);
