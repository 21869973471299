import { classnames } from '@tailwindcss-classnames';

const defaultRounding = classnames('md:rounded-br-md', 'md:rounded-bl-md');

export const contentWrapper = (noSpace: boolean): string =>
  classnames(
    'w-full',
    'lg:max-w-screen-lg',
    'xl:max-w-screen-xl',
    '2xl:max-w-screen-2xl',
    'mx-auto',
    'bg-no-repeat',
    'bg-cover',
    'bg-left-top',
    'relative',
    'p-4',
    'py-6',
    {
      [defaultRounding]: !noSpace,
    }
  );

const darkTextCopy = classnames('text-nzxt-light-grey-600');
const lightTextCopy = classnames('text-nzxt-light-grey-200');
export const textWrapper = (isInverted: boolean): string =>
  classnames('text-xs', 'lg:w-1/3', {
    [darkTextCopy]: isInverted,
    [lightTextCopy]: !isInverted,
  });
