/* eslint-disable no-underscore-dangle */
import { memo, FC, useState, useMemo, useEffect } from 'react';
import GTM from '@utils/gtm';
import { useKeenSlider } from 'keen-slider/react';
import debounce from 'lodash.debounce';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import type { DatoProduct, CustomCard } from '@framework/api/types';
import 'keen-slider/keen-slider.min.css';
import { useMedia, useTranslation as t } from '@utils/hooks';
import ProductCard from '@components/ProductCard';
import ContentWrapper from '@components/ContentWrapper';
import DummyProductCard from '@components/DummyProductCard';
import EditorialCard from '@components/EditorialCard';
import cn from 'clsx';
import extraStyles from './SwimLaneWrapper.module.css';
import * as styles from './styles';

type Props = {
  products: DatoProduct[] | CustomCard[];
  handleSelect?: (i) => void;
  activeItem?: number;
  heading: string;
  headingStyles: string;
  currencyCode?: string;
  isExtendedCard?: boolean;
  asRelatedItems?: boolean;
  eventType?: string;
};

const SwimLaneWrapperProduct: FC<Props> = ({
  products,
  currencyCode,
  isExtendedCard,
  asRelatedItems = false,
  eventType,
}) => {
  const isSmall = useMedia('sm');
  const isMd = useMedia('md');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [width, setWidth] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(null);

  const MOVE_TO_PREV = t('move_to_previous_label');
  const MOVE_TO_NEXT = t('move_to_next_label');

  const isLarge = !isSmall && !isMd;
  const isMoreThanThreeProducts = useMemo(
    () => products.length > 3,
    [products]
  );

  const slidesPer = (sm, md): number => {
    if (sm) {
      return 1.35;
    }

    if (md) {
      return 2.35;
    }

    if (!sm && !md) {
      return 3;
    }

    return 3;
  };

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: { perView: slidesPerView, spacing: 15 },
    mode: 'free-snap',
    loop: false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
      if (asRelatedItems) {
        GTM.dataLayer({
          dataLayer: {
            event: 'recommendededProductsInteraction',
          },
        });
      }
    },
    created() {
      setLoaded(true);
    },
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = debounce(() => setWidth(window.innerWidth), 100);

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  // Fix the fact that keen slider refuses to update per viewport
  const resetSlide = (): void => {
    if (instanceRef?.current) {
      instanceRef.current?.update();
    }
  };
  const debouncedUnfocus = debounce(resetSlide, 100);
  useEffect(() => {
    if (instanceRef?.current) {
      debouncedUnfocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceRef, width]);

  useEffect(() => {
    setSlidesPerView(slidesPer(isSmall, isMd));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmall, isMd]);

  return (
    <div
      className={cn(
        styles.centeredItems,
        {
          [extraStyles.grabbyHand]: isSmall || isMd || isMoreThanThreeProducts,
        },
        extraStyles.wrapperProduct
      )}
    >
      {(isSmall || isMd) && (
        <div ref={sliderRef} className="keen-slider px-3 md:px-8">
          {products.map((product, i) => (
            <div
              key={product.id}
              className={`keen-slider__slide number-slide${i}`}
            >
              <div className={styles.cardSpacing}>
                {product?._modelApiKey === 'product' && (
                  <ProductCard
                    collectionImage={product.collectionPageMainImage}
                    name={product.name}
                    slug={product.slug}
                    variants={product.variants}
                    productPrice={product.collectionPagePrice}
                    currencyCode={currencyCode}
                    shortDescription={product.shortDescription}
                    availableRegions={product.availableRegions}
                    isNude
                    isExtendedCard={isExtendedCard}
                    techSpecs={product.techSpecs}
                    eventType={eventType}
                    rating={product.variants[0]?.shopify_parent_id}
                    isDark={product?.isPrime}
                  />
                )}
                {product?._modelApiKey === 'custom_card' && (
                  <DummyProductCard
                    name={product.title}
                    slug={product.buttonLink}
                    shortDescription={product.shortDescription}
                    extendedHeading={product.subsectionTitle}
                    extendedDetails={[product.subsectionDetails]}
                    ctaTitle={product.ctaTitle}
                    collectionImage={product.mainAsset}
                    isExtendedCard={isExtendedCard}
                    eventType={eventType}
                  />
                )}
                {product?._modelApiKey === 'editorial_card' && (
                  <EditorialCard
                    isInverted={product?.isInverted}
                    contentHorizontalAlignment={
                      product?.contentHorizontalAlignment
                    }
                    contentVerticalAlignment={product?.contentVerticalAlignment}
                    eyebrowLogo={product?.eyebrowLogo}
                    heading={product?.heading}
                    subheading={product?.subheading}
                    internalLink={product?.internalLink}
                    backgroundImage={product?.backgroundImage}
                    backgroundImageAlignment={product?.backgroundImageAlignment}
                    backgroundColor={product?.backgroundColor}
                    gradientColor={product?.gradientColor}
                    buttonLabel={product?.buttonLabel}
                    useLeadText={product?.leadTextStyles}
                    eventType="productGridClickEditorialCard"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <ContentWrapper className="relative">
        {isLarge && isMoreThanThreeProducts && (
          <div className={styles.relative}>
            <div ref={sliderRef} className="keen-slider">
              {products.map((product, i) => (
                <div
                  key={product.id}
                  className={`keen-slider__slide number-slide${i}`}
                >
                  <div className={styles.cardSpacing}>
                    {product?._modelApiKey === 'product' && (
                      <ProductCard
                        collectionImage={product.collectionPageMainImage}
                        name={product.name}
                        slug={product.slug}
                        variants={product.variants}
                        productPrice={product.collectionPagePrice}
                        currencyCode={currencyCode}
                        shortDescription={product.shortDescription}
                        availableRegions={product.availableRegions}
                        isNude
                        isExtendedCard={isExtendedCard}
                        techSpecs={product.techSpecs}
                        eventType={eventType}
                        rating={product.variants[0]?.shopify_parent_id}
                        isDark={product?.isPrime}
                      />
                    )}
                    {product?._modelApiKey === 'custom_card' && (
                      <DummyProductCard
                        name={product.title}
                        slug={product.buttonLink}
                        shortDescription={product.shortDescription}
                        isExtendedCard={product.isExtendedCard}
                        extendedHeading={product.subsectionTitle}
                        extendedDetails={[product.subsectionDetails]}
                        ctaTitle={product.ctaTitle}
                        collectionImage={product.mainAsset}
                        eventType={eventType}
                      />
                    )}
                    {product?._modelApiKey === 'editorial_card' && (
                      <EditorialCard
                        isInverted={product?.isInverted}
                        contentHorizontalAlignment={
                          product?.contentHorizontalAlignment
                        }
                        contentVerticalAlignment={
                          product?.contentVerticalAlignment
                        }
                        eyebrowLogo={product?.eyebrowLogo}
                        heading={product?.heading}
                        subheading={product?.subheading}
                        internalLink={product?.internalLink}
                        backgroundImage={product?.backgroundImage}
                        backgroundImageAlignment={
                          product?.backgroundImageAlignment
                        }
                        backgroundColor={product?.backgroundColor}
                        gradientColor={product?.gradientColor}
                        buttonLabel={product?.buttonLabel}
                        useLeadText={product?.leadTextStyles}
                        eventType="productGridClickEditorialCard"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {isLarge &&
          isMoreThanThreeProducts &&
          loaded &&
          instanceRef.current && (
            <>
              <button
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any): void =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
                type="button"
                style={{ top: '40%', left: 0 }}
                className={styles.sliderButton}
              >
                <span className={styles.srOnly}>{MOVE_TO_PREV}</span>
                <IconChevronLeft className={styles.arrow(currentSlide === 0)} />
              </button>
              <button
                type="button"
                style={{ top: '40%', right: 0 }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 3
                }
                className={styles.sliderButton}
              >
                <span className={styles.srOnly}>{MOVE_TO_NEXT}</span>
                <IconChevronRight
                  className={styles.arrow(
                    currentSlide ===
                      instanceRef.current.track.details.slides.length - 3
                  )}
                />
              </button>
            </>
          )}
        {isLarge && !isMoreThanThreeProducts && (
          <div className={styles.fewItemsGrid}>
            {products.map(product => (
              <div key={product.id} className={styles.fewItemsSpacin}>
                {product?._modelApiKey === 'product' && (
                  <ProductCard
                    collectionImage={product.collectionPageMainImage}
                    name={product.name}
                    slug={product.slug}
                    variants={product.variants}
                    productPrice={product.collectionPagePrice}
                    currencyCode={currencyCode}
                    shortDescription={product.shortDescription}
                    availableRegions={product.availableRegions}
                    isNude
                    isExtendedCard={isExtendedCard}
                    techSpecs={product.techSpecs}
                    regionalServices={product?.regionalServices || []}
                    eventType={eventType}
                    rating={product.variants[0]?.shopify_parent_id}
                    isDark={product?.isPrime}
                  />
                )}
                {product?._modelApiKey === 'custom_card' && (
                  <DummyProductCard
                    name={product.title}
                    slug={product.buttonLink}
                    shortDescription={product.shortDescription}
                    isExtendedCard={product.isExtendedCard}
                    extendedHeading={product.subsectionTitle}
                    extendedDetails={[product.subsectionDetails]}
                    ctaTitle={product.ctaTitle}
                    collectionImage={product.mainAsset}
                    eventType={eventType}
                  />
                )}
                {product?._modelApiKey === 'editorial_card' && (
                  <EditorialCard
                    isInverted={product?.isInverted}
                    contentHorizontalAlignment={
                      product?.contentHorizontalAlignment
                    }
                    contentVerticalAlignment={product?.contentVerticalAlignment}
                    eyebrowLogo={product?.eyebrowLogo}
                    heading={product?.heading}
                    subheading={product?.subheading}
                    internalLink={product?.internalLink}
                    backgroundImage={product?.backgroundImage}
                    backgroundImageAlignment={product?.backgroundImageAlignment}
                    backgroundColor={product?.backgroundColor}
                    gradientColor={product?.gradientColor}
                    buttonLabel={product?.buttonLabel}
                    useLeadText={product?.leadTextStyles}
                    eventType="productGridClickEditorialCard"
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </ContentWrapper>
    </div>
  );
};

export default memo(SwimLaneWrapperProduct);
