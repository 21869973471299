import { classnames } from '@tailwindcss-classnames';

const baseTextPlacement = classnames(
  'flex',
  'px-6',
  'md:px-0',
  'py-16',
  'justify-center',
  'items-center',
  'flex-col-reverse'
);
const baseTextSpacingV = classnames('space-y-4', 'lg:space-y-12');

const baseTextSpacingH = classnames(
  'space-y-4',
  'md:space-y-0',
  'lg:space-x-12'
);

export const defaultRounding = classnames('md:rounded-md');
export const firstRounding = classnames('md:rounded-tr-md', 'md:rounded-tl-md');
export const lastRounding = classnames('md:rounded-br-md', 'md:rounded-bl-md');

export const contentWrapper = classnames(
  'w-full',
  'md:w-11/12',
  'mx-auto',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'xl:px-20',
  'relative'
);

export const backgroundContainer = classnames(
  'bg-no-repeat',
  'p-4',
  'bg-cover',
  'relative'
);

const bottomSpacing = classnames('mb-3', 'md:mb-6', 'xl:mb-8');
export const noSpace = classnames('py-0');

export const spacingWrapper = (
  isMoreThanOne: boolean,
  isNoSpace: boolean
): string =>
  classnames({
    [bottomSpacing]: isMoreThanOne && !isNoSpace,
  });

const left = classnames(baseTextPlacement, baseTextSpacingH, 'lg:flex-row');
const bottom = classnames(
  baseTextPlacement,
  baseTextSpacingV,
  'lg:flex-col-reverse'
);
const top = classnames(baseTextPlacement, baseTextSpacingV, 'lg:flex-col');
const right = classnames(
  baseTextPlacement,
  'space-y-4',
  'md:space-y-0',
  'lg:flex-row-reverse'
);

export const textPlacement = {
  left,
  bottom,
  top,
  right,
};

export const image = classnames(
  'w-full',
  'h-full',
  'max-w-xl',
  '2xl:max-w-2xl',
  'object-contain'
);
export const partnerLogo = classnames('w-full', 'h-full');

export const partnerLogoPos = classnames('absolute', 'bottom-10', 'right-10');
